<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Plans ({{ listMeta.total }})</v-toolbar-title
      >
    </v-app-bar>

    <div
      class="mt-12 mx-4"
      id="user-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th width="20%" colspan="2" class="text-left pa-0">Name</th>
            <th width="20%" class="text-left">Storage Limit</th>
            <th width="20%" class="text-left">Description</th>
            <th width="20%" class="text-left">Prices</th>
            <th width="20%" class="text-left">Days before deletion</th>
            <th width="20%" class="text-left">Added date</th>
            <th width="20%" class="text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="plan in plans" :key="plan.id" class="clickable">
            <td colspan="2">
              {{ plan.name }}
            </td>
            <td>{{ plan.storage_limit }}GB</td>
            <td>{{ plan.description }}</td>
            <td>
              <div v-if="plan.prices && plan.prices.length">
                <ul>
                  <li v-for="(item, index) in plan.prices" :key="index">
                    <p
                      @click="
                        if (item.amount) {
                          selectedPrice = item
                          newPrice = item.amount
                          updatePriceModal = true
                        }
                      "
                      :class="
                        item.amount
                          ? 'primary--text text-decoration-underline'
                          : ''
                      "
                    >
                      {{
                        `$${item.amount} / ${
                          item.recurring ? item.recurring.interval : 'lifetime'
                        }`
                      }}
                    </p>
                  </li>
                </ul>
              </div>
            </td>

            <td>{{ plan.days_before_deletion }} Days</td>
            <td>{{ plan.addedDate }}</td>
            <td>
              <v-btn
                small
                class="primary mr-2"
                icon
                :disabled="loading"
                @click="
                  createPlanModal = true
                  selectedItem = plan
                "
              >
                <v-icon small color="white">{{ icons.edit }}</v-icon>
              </v-btn>
            </td>
          </tr>

          <template v-if="loading">
            <tr v-for="index in 10" :key="index + '-skeleton'">
              <td class="py-3 pl-0" width="5%">
                <v-skeleton-loader
                  class="avatar-loading--36"
                  type="avatar"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>

      <div
        class="d-flex justify-center mt-3"
        v-if="!(plans.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" right>
      {{ snackbar.message }}
      <v-btn color="white" text @click="snackbar.show = false">Ok</v-btn>
    </v-snackbar>

    <v-dialog v-model="updatePriceModal" persistent max-width="400px">
      <v-form
        v-model="updatePriceFormIsValid"
        ref="priceForm"
        @submit.prevent="handlePriceChange"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Edit Price</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model.number="newPrice"
                    label="New Price"
                    :rules="[rules.required]"
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="updatePriceModal = false">
              Close
            </v-btn>
            <v-btn
              class="primary"
              :loading="loading"
              text
              @click="handlePriceChange"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="createPlanModal" persistent max-width="400px">
      <v-form v-model="isValidForm" ref="form" @submit.prevent="handlePlan">
        <v-card>
          <v-card-title>
            <span class="text-h5">Edit Plan</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="form.name"
                    label="Name"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="form.storage_limit"
                    label="Storage Limit"
                    :rules="[rules.required]"
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    v-model="form.description"
                    label="Description"
                    :rules="[rules.required]"
                    auto-grow
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="form.days_before_deletion"
                    label="Days Before Deletion"
                    :rules="[rules.required]"
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="createPlanModal = false">
              Close
            </v-btn>
            <v-btn class="primary" :loading="loading" text @click="handlePlan">
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiFileExcel,
  mdiArrowUp,
  mdiArrowDown,
  mdiPlusCircle,
  mdiPencil,
} from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'

export default {
  name: 'PlansPage',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  watch: {
    selectedItem(value) {
      this.form.name = value.name
      this.form.storage_limit = value.storage_limit
      this.form.days_before_deletion = value.days_before_deletion
      this.form.description = value.description
    },
  },

  data() {
    return {
      loading: false,
      showSearch: false,
      showProfile: false,
      selectedItem: null,
      selectedPrice: {},
      filter: {
        search: '',
      },
      rules: {
        required: (value) => !!value || 'Required Field.',
      },
      icons: {
        createPlan: mdiPlusCircle,
        edit: mdiPencil,
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        export: mdiFileExcel,
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
      },
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      defaultFilter: 'first_name',
      sortOrder: true,
      form: {
        name: '',
        storage_limit: '',
        days_before_deletion: '',
        description: '',
      },
      createPlanModal: false,
      deletePlan: false,
      isValidForm: false,
      updatePriceFormIsValid: false,
      updatePriceModal: false,
      newPrice: null,
    }
  },

  created() {
    this.clearPlanList()
    this.fetchPlanList(1)
  },

  computed: {
    ...mapState({
      plans: (state) => state.plan.list,
      listMeta: (state) => state.plan.listMeta,
    }),

    paginationLength() {
      return (this.listMeta.total / this.listMeta.per_page) | 0
    },
  },

  methods: {
    ...mapActions({
      getPlanList: 'plan/getPlanList',
      updatePlan: 'plan/updatePlan',
      updatePrice: 'plan/updatePrice',
    }),

    ...mapMutations({
      clearPlanList: 'plan/clearPlanList',
    }),

    async handlePriceChange() {
      if (this.newPrice && this.newPrice !== this.selectedPrice.amount) {
        try {
          this.loading = true
          await this.updatePrice({
            form: { amount: this.newPrice },
            priceId: this.selectedPrice.id,
            planId: this.selectedPrice.plan_id,
          })

          this.selectedPrice = {}
          this.newPrice = null

          this.$refs.priceForm.reset()
          this.loading = false
          this.updatePriceModal = false
          this.showSnackbar('Price has been updated', 'success')
          await this.clearPlanList()
          await this.fetchPlanList()
        } catch (error) {
          this.loading = false
        }
      }
    },

    async handlePlan() {
      this.$refs.form.validate()
      if (this.isValidForm) {
        try {
          this.loading = true
          await this.updatePlan({ form: this.form, id: this.selectedItem.id })
          this.form = {
            name: '',
            storage_limit: '',
            days_before_deletion: '',
            description: '',
          }

          this.$refs.form.reset()
          this.loading = false
          this.createPlanModal = false
          this.showSnackbar('Plan content has been updated', 'success')
          await this.clearPlanList()
          await this.fetchPlanList()
        } catch (error) {
          this.loading = false
        }
      }
    },

    pageChanged(page) {
      this.fetchPlanList(page)
    },

    filterPlan(filterValue) {
      this.clearPlanList()
      this.fetchPlanList(null, filterValue)
    },

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchPlanList(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearPlanList()
      this.fetchPlanList()
    }, 600),

    async fetchPlanList(page = 1) {
      if (this.loading) return
      let params = {
        page,
        sort: this.sortOrder
          ? `${this.defaultFilter}`
          : `-${this.defaultFilter}`,
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }
      this.loading = true
      await this.getPlanList(params)
      this.loading = false
    },

    // showProfileDialog(profile) {
    //   this.selectedProfile = profile
    //   this.showProfile = true
    // },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.color = color
      this.snackbar.show = true
    },
    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    },
  },
}
</script>
